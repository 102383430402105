/* Estilos para o logotipo */
.enter-logo {
    margin: 43px 0 30px 0;
    width: 161px;
    height: 49px;
    background: transparent url('../../images/enter-logo.png') 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  
  .enter-logo-hr {
    margin: 43px 0 30px 0;
    width: 208px;
    height: 49px;
    background: transparent url('../../images/enter-logo-hr.png') 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  
  /* Estilos para o toggle do menu */
  .menuToggle {
    display: block;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }
  
  .menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #49545C;
    border-radius: 3px;
    z-index: 1;
  }
  
  .mobile-control {
    display: none;
  }
  
  .desktop-control {
    display: block;
  }
  
