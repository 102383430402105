.card-background-custom {
  background: #2f3941 0% 0% no-repeat padding-box;
  opacity: 0.85;
  margin: 0px;
  padding: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.card-custom {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2f394129;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 1rem;
}

.title-card {
  top: 196px;
  left: 331px;
  width: 278px;
  height: 82px;
  text-align: left;
  font: Semibold 22px/52px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0;
  color: #2f3941;
  opacity: 1;
}

.c-btn--basic {
  background: white 0% 0% no-repeat padding-box !important;
  color: #de701d !important;
  border: white 1px solid !important;
  border-radius: 4px;
  opacity: 1;
}

.c-btn--basic:hover {
  background: white 0% 0% no-repeat padding-box !important;
  color: #de701d !important;
  border: #de701d 1px solid !important;
  border-radius: 4px;
  opacity: 1;
}

.btn-exclude-pre-register {
  background-color: transparent;
  border: none;
  color: #de701d;
}

.btn-exclude-pre-register:hover {
  text-decoration: underline;
}

.cancel-delete-user-pre-register {
  padding: 5px 50px !important;
  border-radius: 25px !important;
  background-color: #ea951d !important;
  border: unset !important;
}

.delete-user-pre-register {
  padding: 5px 50px !important;
  border-radius: 25px !important;
  background-color: white !important;
  border: 2px solid #ea951d !important;
  color: #ea951d !important;
}

.button-delete-cv-pre-register {
  padding: 0;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: medium;
}

