.faq-header {
  margin-top: 2rem;
  text-align: left;
  font: normal normal 600 20px/27px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #2e3f51;
  opacity: 1;
}

ul {
  /* list-style: none; */
  padding: 0;
  margin: 0;
}

.faq-items {
  margin-top: 2rem;
  margin-bottom: 4rem;
  list-style-type: none;
  padding: 0;
  text-align: left;
  font: normal normal normal 18px/24px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.faq-items li {
  list-style-type: none;
}

.faq-items ol {
  padding: 0;
}

.faq-rh-titles {
  color: #707070;
  font-weight: 700;
}

.header-table-faq {
  display: flex;
}

.body-table-faq {
  width: 100%;
  font-size: 0.8rem;
  font-weight: 600;
}

.body-item-faq {
  border: solid 1px #707070;
  height: 40px;
  text-align: center;
}

.body-item-faq:first-child {
  background-color: #c0c0c0;
}

@media (max-width: 768px) {
  .header-table-faq {
    flex-direction: column;
  }
  .body-item-faq {
    border: none;
    border-top: solid 1px #707070;
  }
  .body-table-faq {
    border: solid 1px #707070;
  }

}
