$ligth-color: white;
$dark-color: #C2C8CC;
$orange-color: #ED961C;

.main-container {
    width: 80vw;
    border: solid 1px #ebe9e9;
    box-shadow: 1px 1px 8px#8D8D8D;
    margin: 0 auto;
    border-radius: 10px;
    margin-bottom: 5vw;
}

.position-content {
    display: flex;
    justify-content: center;
    margin-top: 2vw;
}

.position-content-two>div:last-child {
    display: flex;
    justify-content: flex-end;
}

.button-style {
    border-radius: 24px;
    width: 15vw;
    font-size: 24px;
}

.input-search-interests>:first-child>div>div{
    background-color: #8D8D8D;
    color: black;
    border-radius: 25px;
    border: none;
}

.input-search-skill>:first-child>div>div {
    background-color: $orange-color;
    border-radius: 25px;
    border: none;
    color: $ligth-color;
}

.input-search-skill>:first-child>div>div:hover {
    background-color: #e7b166;
    color: white;
    border-radius: 25px;
    border: none;
}

.input-search-skill-overlay>:first-child {
    border: solid 1px red;
    border-radius: 5px;
}

.color-light {
    background-color: transparent;
    color: #FF4E22;
    border: solid 1px #FF4E22;
}

.color-dark {
    background-color: #FF4E22;
    color: white;
    border: solid 1px #FF4E22;
}

.style-label {
    color:#FF7C59;
    font-weight: 500;
}

.field-professional-profile {
    border: solid 1px #ebe9e9;
    border: solid 1px #ccc6c6;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    font-family: inherit;
    line-height: 1.2857142857142858;
    font-size: 14px;
    width: 100%;
    div,
    div:hover,
    div:focus {
      border: 0px !important;
      border-color: none;
      box-shadow: none;
    }
  }
  .alert-fields {
    position: absolute;
    // margin: 0 auto;
    // border: solid 1px red;
  }

  @media (max-width: 768px) {
    .alert-fields {
        position: relative; /* Garante que a posição seja relativa no mobile */
        margin-top: 10px; /* Adiciona um espaço entre as mensagens */
        display: block; /* Garante que a mensagem ocupe toda a largura */
    }
}
