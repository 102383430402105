/* No Squad */
.border-left- {
  border-left: 0.25rem solid #ababab !important;
}
.squad-color- {
  color: #ababab;
}
.squad-background- {
  background-color: #ababab;
}

/* Shark Attack */
.border-left-5e0655001c9d44000033088d {
  border-left: 0.25rem solid #3498db !important;
}
.squad-color-5e0655001c9d44000033088d {
  color: #3498db;
}
.squad-background-5e0655001c9d44000033088d {
  background-color: #3498db;
}

/* Caravela Portuguesa */
.border-left-5e0655091c9d44000033088e {
  border-left: 0.25rem solid #1abc9c !important;
}
.squad-color-5e0655091c9d44000033088e {
  color: #1abc9c;
}
.squad-background-5e0655091c9d44000033088e {
  background-color: #1abc9c;
}

/* The Black Dogs */
.border-left-5e0655e21c9d44000033088f {
  border-left: 0.25rem solid #e74c3c !important;
}
.squad-color-5e0655e21c9d44000033088f {
  color: #e74c3c;
}
.squad-background-5e0655e21c9d44000033088f {
  background-color: #e74c3c;
}

/* Golden Falcon */
.border-left-5e0655ea1c9d440000330890 {
  border-left: 0.25rem solid #f39c12 !important;
}
.squad-color-5e0655ea1c9d440000330890 {
  color: #f39c12;
}
.squad-background-5e0655ea1c9d440000330890 {
  background-color: #f39c12;
}

.cancel-modal-button {
  width: 20px;
  height: 20px;
  border-radius: 5px !important;
  background-color: red !important;
  border: unset !important;
  color: white;
  text-align: center;
  line-height: 2px;
}

.candidates-counter {
  height: 120px;
  background: #f5f5f5;
  border-radius: 4px;
  color: #4f6061;
}

.hb-divider {
  height: 20px;
  border-bottom: 1px #b5b5b5 solid !important;
}
.candidates-link a {
  color: #4f6061;
}

.title-qty-opp {
  border-radius: 4px;
  opacity: 1;
  height: 51px;
  color: #fff;
  vertical-align: middle;
}

.input-select-qty {
  border: 1px solid transparent ;
  border-radius: 3px;
  background-color: #fff;
  width: 40px;
  color: #000;
  padding-left: 5px;
}

/* Borda do dropdown */
.icyLxM {
  padding: 0.9em !important;
}