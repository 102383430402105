.size-line {
  height: 10px;
}

.opportunities-applied {
    color: white;
    padding: 15px 10px;
    border-radius: 4px;
    background-color: #354A5F;
    box-shadow: 2px 2px 4px #00000029;
    width: 100%;
    border: none;
    text-align: left;
}

.applied-list li {
  margin-bottom: 4px;
}

.title-area {
  font: normal normal 600 16px/21px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #354A5F;
}

.accordion-header > svg {
  display: none !important;
}

.title-objective {

  font: normal normal 600 13px/17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #354A5F;
  opacity: 1;

}

.professional-title img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.container-img-professional {
  width: 100%;
  border-radius: 20px 20px 0 0;
  background-color: #f7f5ff;
  padding: 15px 5px 5px 5px;
  display: flex;
  justify-content: center;
}

.info-title {
  font: normal normal 600 13px/17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #8F969B;
}

.info-content {
  font: normal normal normal 13px/17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #8F969B;
  opacity: 1;
}

.content-size {
  width: 100%;
}

.parecer-comportamental-header {
  background-color: #EFF2F2;
  border-radius: 14px;
  color: #354A5F;
  font: normal normal 600 13px/17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  margin: 1%;
  padding: 1rem;
}

.text-behavioral {
  color: #354A5F;
  background-color: #EFF2F2;
}

.add-cv {
  background-color: transparent;
  border: none;
  color: #354A5F;
  font-weight: 700;
}

.delete-cv {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #657585;
  font-weight: 600;
  font-family: cursive;
  font-size: 0.75rem;
  
}

.delete-cv:active {
  cursor: pointer;
  color: #657585;
  font-weight: 600;
  font-family: cursive;
  font-size: 0.9rem;
  height: 20px;
}

.container-professional-expectation {
  margin: 0 auto;
  width: 95%;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  box-shadow: 1px 1px 5px #354a5f;
  padding: 5px;
}

.container-professional-header {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 1px 1px 5px #354a5f;
}

.button-show-feed {
  width: 100%;
  border: none;
  background-color: transparent;
  color: #354A5F;
  padding-bottom: 2px;
  flex-basis: 100%;
}

.button-orange {
  background-color: #ed961c !important;
}

.style-scroll-bar-find::-webkit-scrollbar {
  width: 12px;
}

.style-scroll-bar-find::-webkit-scrollbar-track {
  background: white;
}

.style-scroll-bar-find::-webkit-scrollbar-thumb {
  background-color: #ed961c;
  border-radius: 20px;
  border: 3px solid white;
}

.modal-button-opp {
  position: relative;
  width: 100%;
  background-color: #7b8896 !important;
  &:hover {
    background-color: #ed961c !important;
    transition: 0.2s;
  }
}

.modal-button-selected {
  position: relative;
  width: 100%;
  background-color: #ed961c !important;
  &:hover {
    background-color: #ffa526 !important;
    transition: 0.2s;
  }
}

.info {
  fill: white;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 1000;
}

.professional-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.field-toggle {
  display: flex;
  max-width: 20rem;
  flex-wrap: wrap;
}

.field-toggle-item {
  flex: 1;
	margin: 5px;
}

.update-style {
  font: normal normal 600 14px/19px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #354A5F;
}

.view-select-button-prof-profile,
.view-select-button-prof-profile > div {
  height: 3rem !important;
  border-radius: 5px;
  color: #707070 !important;
}

.view-select-button-prof-profile:first-child,
.view-select-button-prof-profile:nth-child(3) {
  margin-right: 4px;
}

.view-label-select-prof-profile {
  position: absolute;
  top: 4px;
  left: 11px;
  font: normal normal 600 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji" !important;
  padding-top: 2px;
  color: #707070 !important;
}

.view-select-button-moeda,
.view-select-button-moeda > div {
  height: 3rem !important;
  border-radius: 5px;
  display: block;
  margin-bottom: 20px;
  color: #707070 !important;
}

.view-select-button-moeda:first-child,
.view-select-button-moeda:nth-child(3) {
  margin-right: 5px;
}

.view-select-button-valor,
.view-select-button-valor > div {
  height: 3rem !important;
  border-radius: 5px;
  display: block;
  margin-bottom: 20px;
  color: #707070 !important;
}

.view-select-button-valor:first-child,
.view-select-button-valor:nth-child(3) {
  margin-right: 5px;
}

.view-label-input-value {
  font: normal normal 600 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  top: 2px;
  padding: 5px;
  color: #707070 !important;
}

.view-coin-container-pro-profile {
  display: flex;
  justify-content: space-around;
  width: 70%;
}

.view-title-salary-cap {
  margin-bottom: 8px;
  color: #707070 !important;
  font: normal normal 600 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji" !important;
}

.view-price-field {
  width: 50%;
}
.view-coin-field {
  width: 50%;
  margin-right: 0.5rem;
}

.view-salary-expectation-style {
  background-color: transparent;
  border: none;
  color: #707070;
  text-align: left;
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
  width: 100%;
}

.view-close-modal-pretensao {
  background-color: transparent;
  border: none;
  color: #8F969B;
  font-size: 1.5rem;
  padding: 0;
}

.view-button-save-expectation {
  padding: 5px 50px !important;
  border-radius: 25px !important;
  background-color: #ea951d !important;
  border: unset !important;
}

.editButtonTechnicalAdvice {
  background-color: transparent;
  border: 1px solid #354A5F;
  border-radius: 20px;
  color: #354A5F;
  font-weight: 500;
  padding: 5px 20px;
}

.header-modal-edit {
  font-size: x-large;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #8F969B;
  width: 100%;
  text-align: center;
}

.modal-body-edit {
  margin:15px;
  border: solid 1px #e2dddd;
  border-radius: 7px;
  background-color: transparent;
}

.modal-footer-save-button {
  border: solid 1px #ea951d;
  background-color: #ea951d;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: white;
  font-size: larger;
}

@media(max-width: 1200px) {
  .field-toggle {
    max-width: 12rem;
  }
}

@media(max-width: 767px) {
  .view-coin-container-pro-profile {
    display: flex;
    flex-direction: column;
  }

  .container-img-professional {
    border-radius: 20px 20px 0 0;
    background-color: #f7f5ff;
    width: 100%;
  }

  .view-coin-container-pro-profile>div {
    margin: 0.2rem;
  }

  .container-professional-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .field-toggle {
    max-width: 26rem;
    margin-left: 1rem;
  }

  .container-professional-expectation {
    margin: 0 auto;
    width: 93%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .professional-picture {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 5%;
  }

  .professional-info {
    text-align: center;
    width: 100%;
  }

  .view-price-field {
    width: 100%;
  }
  .view-coin-field {
    width: 100%;
    margin-right: 0.5rem;
  }

  .view-salary-expectation-style {
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }
}

@media(max-width: 532px) {
  .container-professional-header {
    display: flex;
    flex-direction: column;
  }

  .field-toggle {
    max-width: 20rem;
  }

  .container-professional-expectation {
    margin: 0 auto;
    width: 93%
  }

  


  .container {
    display: flex;
    flex-direction: column;
  }
  
  .user-info {
    margin-bottom: 10px; /* Espaçamento entre as divs */
  }
  
  .new-div {
    margin-bottom: 10px; /* Espaçamento entre as divs */
  }
}

